"use strict";
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
Object.defineProperty(exports, "__esModule", { value: true });
require("./src/styles/app.css");
document.addEventListener('DOMContentLoaded', () => {
    console.log('hello');
});
